<template>
    <!--
    Диалог добавления недвижимости
    -->
    <el-dialog
        :width="dialogWidth"
        class="add-realty-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :show-close="false"
        :modal-append-to-body="false">

        <template v-slot:title>
            <dom-title-closable value="Добавление недвижимости пользователя" close-text="Завершить без сохранения" @close="onCancel"/>
        </template>

        <el-card class="realty-dialog-wrapper">


            <template v-for="item in properties">
                <el-row :gutter="1">
                    <dom-dropdown v-model="item.property"
                                  :items="allProperties"
                                  left-icon="el-icon-location"
                                  label="Выберите адрес объекта недвижимости"
                                  text-key="address" value-key="property_id"/>
                </el-row>

              <template v-if="needPropertyDetails">
                <el-row :gutter="10" class="m-10">
                  <el-col :xs="24">
                    <dom-input v-model="item.name" placeholder="Наименование объекта права"/>
                  </el-col>
                </el-row>
                <el-row :gutter="1">
                  <dom-dropdown v-model="item.lawType"
                                :items="lawTypes"
                                label="Выберите вид права"
                                text-key="description" value-key="id"/>
                </el-row>
                <el-row :gutter="10" class="m-10">
                  <el-col :xs="24">
                    <dom-input v-model="item.regDocument" placeholder="Документ основания права"
                               class="dialog__custom_col"/>
                  </el-col>
                </el-row>

                    <el-row :gutter="1">
                        <dom-dropdown v-model="item.propertyType"
                                      :items="propertyTypes"
                                      label="Выберите тип объекта"
                                      text-key="objType" value-key="value"/>
                    </el-row>
                    <el-row :gutter="10" class="m-10">
                        <el-col :xs="24">
                            <template v-if="item.propertyType">
                                <dom-input v-model="item.objNumber" v-if="item.propertyType.value === 'иное'" placeholder="Объект"/>
                                <dom-input v-model="item.objNumber" v-else type="number" placeholder="Объект, №"/>
                            </template>
                        </el-col>
                        <el-col :xs="24">
                            <dom-input v-model="item.area" placeholder="Общая площадь, м2" type="number" class="dialog__custom_col"/>
                        </el-col>
                    </el-row>

                  <el-row :gutter="10" class="m-10">
                    <el-col :xs="24">
                      <dom-input v-model="item.propertyArea" placeholder="Голосующая площадь, м2" type="number"
                               class="dialog__custom_col"/>
                    </el-col>
                  </el-row>

                    <el-row :gutter="10" class="m-10 mb-40">
                        <el-col :xs="24">
                            <dom-datepicker v-model="item.regDate" placeholder="Дата регистрации права" suffix-icon="fas el-icon-fa-calender"/>
                        </el-col>

                        <el-col :xs="24">
                            <dom-input v-model="item.egrnNo" placeholder="Номер ЕГРН" class="dialog__custom_col"/>
                        </el-col>

                        <el-col :xs="24">
                            <dom-input v-model="item.cadNumber" placeholder="Кадастровый номер" class="dialog__custom_col"/>
                        </el-col>

                        <el-col :xs="24">
                            <dom-input v-model="item.commonNumber" placeholder="Условный номер" class="dialog__custom_col"/>
                        </el-col>

                    </el-row>

                </template>

            </template>

            <el-row class="mb-40">
                <dom-button-decline @click="addProperty()" left-icon="fas el-icon-fa-plus-circle" label="Добавить еще один объект"/>
                <dom-button-accept @click="onFinish" :disabled="!formPropertiesCompleted" label="Сохранить и завершить"/>
            </el-row>
        </el-card>
    </el-dialog>

</template>

<script>

import Toggleable from "@/views/mixins/Toggleable";
import DomDropdown from "@/views/elements/DomDropdown";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import DomInput from "@/views/elements/DomInput";
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomDatepicker from "@/views/elements/DomDatepicker";
import DomButtonDecline from "@/views/elements/DomButtonDecline";
import moment from "moment";
import VueScreenSize from "vue-screen-size";

export default {
    components: {
        DomButtonDecline,
        DomDatepicker,
        DomButtonAccept,
        DomTitleClosable,
        DomInput,
        DomTextarea,
        DomButton,
        DomDropdown,
    },
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],

    data: () => ({
        properties: [],
    }),


    computed: {
        ...mapFields("property", {allProperties: 'properties', propertyTypes: 'propertyTypes', lawTypes: 'lawTypes'}),
        ...mapFields("user", ['currentUser']),
        ...mapGetters("user", ["uxpType", "needPropertyDetails"]),

        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        formPropertiesCompleted() {
            const filled = _(this.properties).map(item =>
                item.property.property_id &&
                item.name &&
                item.lawType &&
                item.regDocument &&
                item.area &&
                item.propertyType &&
                item.regDate &&
                item.cadNumber &&
                item.egrnNo
            )
                .filter().value();

            return filled.length === this.properties.length
        },


    },

    created() {
        this.addProperty()
        this.getLawTypes()
    },

    methods: {
        ...mapActions("property", {getAllProperties: "getAll", getLawTypes: "getLawTypes"}),
        ...mapActions("user", ['add_user_properties', 'get_props']),

        addProperty() {
            if (this.needPropertyDetails)
                this.addPropertyForOwner()
            else
                this.addPropertyForRuler();
        },

        addPropertyForRuler() {
            this.properties.push({
                property: {},
                type: 'ruler',
                name: 'for link',
                lawType: 'for link',
                regDocument: 'for link',
                propertyType: 'for link',
                objNumber: 'for link',
                area: -1,
                regDate: moment().toISOString(true),
                commonNumber: -1,
                cadNumber: -1,
                egrnNo: -1,
            })
        },

        addPropertyForOwner() {
            this.properties.push({
                property: {},
                type: 'owner',
                name: null,
                lawType: null,
                propertyType: null,
                objNumber: null,
                regDocument: null,
                area: null,
                regDate: null,
                commonNumber: null,
                cadNumber: null,
                egrnNo: null,
            })
        },


        onCancel() {
            this.isActive = false;
        },

        onFinish() {
            const user_id = this.currentUser.userId;
            const data = this.properties;
            data.forEach(el => {
                if (el.propertyType.objType !== 'Иное')
                    el.address = el.propertyType.value + '. ' + el.objNumber
                else {
                    el.address = el.objNumber
                }
            });
            this.add_user_properties({user_id, data}).then(() => {
                this.$message.success(`Информация успешно сохранена.`);
                this.get_props(user_id)
                this.isActive = false;
            })
        },

    },
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.inpt-label {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: flex-end;
}

.dialog__custom_col {
    margin-top: 10px;
}

.dialog_full_display {
    width: 100%;
}

.add-realty-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }

    .realty-dialog-wrapper {
        background-color: $dom-light-gray;

        .realty-dialog__raw {
            margin: 10px;
        }
    }
}
</style>
